import Dashboard from "components/index/Dashboard";
import AuthGuard from "guards/AuthGuard";
import MainLayout from "layouts/main/MainLayout";
import type { NextPage } from "next";

const Home: NextPage = () => {
  return (
    <AuthGuard>
      <MainLayout>
        <Dashboard />
      </MainLayout>
    </AuthGuard>
  );
};

export default Home;
