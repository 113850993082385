import { Box, Button, Divider, Typography } from "@mui/material";
import Link from "next/link";
import React from "react";

interface IReportAutomationDashboardProps {}
export default function ReportAutomationDashboard(
  props: IReportAutomationDashboardProps
) {
  return (
    <div>
      <Typography variant="h5">Report Automation</Typography>
      <Divider sx={{ my: 1 }} />

      <Box sx={{ display: "flex", alignContent: "center", columnGap: 1 }}>
        <Link href="/business-entity">
          <Button>Business Entity List</Button>
        </Link>
        <Link href="/entity-sales-summary">
          <Button>Daily Sales</Button>
        </Link>
        <Link href="/entity-hourly-sales">
          <Button>Hourly Sales</Button>
        </Link>
        <Link href="/sales-comparison-report">
          <Button>Sales Comparison Summary</Button>
        </Link>
      </Box>
    </div>
  );
}
