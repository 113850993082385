import { Badge, Theme, useTheme } from "@mui/material";
import { useQuery } from "react-query";
import AuthServices from "services/AuthServices";
import { bSdk } from "services/BackendSDKService";
import Utils from "services/Utils";
import { IEnumOptions } from "types/custom-enum";

interface IRenderEnum {
  code: string;
  value?: string;
  chip?: any;
  noColor?: any;
  count?: number;
  renderAsString?: boolean;
  /** It will render enum's value as it is if matching able is not found.  */
  renderValueIfLabelNotFound?: boolean;
  render?: (option: IEnumOptions) => void;
}

export default function RenderEnum(props: IRenderEnum) {
  const customEnum = useQuery("custom-enum-auto-complete", async () => {
    const customEnumQuery = await bSdk.cmsQuery("custom-enum", undefined, {
      route: "/",
      method: "get",
      authToken: AuthServices.getToken(),
      query: { page: 0, pageSize: 0 },
    });

    return customEnumQuery.data;
  });

  const theme = useTheme<Theme>();

  function getCustomEnum(data: any) {
    const enumData = data?.find((item: any) => item._id === props.code);
    return enumData?.Options.find((item: any) => item.Value === props.value);
  }

  const enumObj = getCustomEnum(customEnum.data);

  if (!enumObj)
    return (
      <div style={{ width: "1px" }}>
        {props.renderValueIfLabelNotFound && props.value}
      </div>
    );
  let label = enumObj?.Label;
  if (!label && props.renderValueIfLabelNotFound) label = props.value;
  if (props.render) {
    return props.render(enumObj);
  }
  if (props.chip) {
    return (
      <div>
        <Badge badgeContent={props.count ? props.count : 0}>
          <div
            style={{
              color: Utils.getContrastText(theme, enumObj?.BackgroundColor),
              fontWeight: "bold",
              backgroundColor: props.noColor ? "" : enumObj?.BackgroundColor,
              padding: "5px 8px",
              borderRadius: 20,
              fontSize: 13,
            }}
          >
            {label}
          </div>
        </Badge>
      </div>
    );
  }
  if (props.renderAsString) return label;

  return (
    <div style={{ color: props.noColor ? "" : enumObj?.BackgroundColor }}>
      {label}
    </div>
  );
}
