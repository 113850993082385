import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PeopleIcon from "@mui/icons-material/People";
import RuleIcon from "@mui/icons-material/Rule";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Divider, Grid, Typography } from "@mui/material";
import { useList } from "hooks/commonQuries";
import Link from "next/link";
import Utils from "services/Utils";
import { IDashboardCounts } from "types/masterClient";
import DashboardCard from "../DashboardCard";

interface ITaxFilingAutomationDashboardProps {}
export default function TaxFilingAutomationDashboard(
  props: ITaxFilingAutomationDashboardProps
) {
  const objDashboardCounts = useList<IDashboardCounts>(
    "master-client/getDashboard"
  );

  if (!objDashboardCounts.data) return null;
  return (
    <>
      <Typography variant="h5">Tax Filing Dashboard</Typography>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <a href={"/sub-client"} target="_blank" rel="noreferrer">
            <DashboardCard
              isLoading={objDashboardCounts.isLoading}
              icon={<PeopleIcon sx={{ fontSize: "32px" }} />}
              title={objDashboardCounts.data[0].TotalSubClients.label}
              value={Utils.showNumber(
                objDashboardCounts.data[0].TotalSubClients.value
              )}
            />
          </a>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <a href={"/tax-filings"} target="_blank" rel="noreferrer">
            <DashboardCard
              isLoading={objDashboardCounts.isLoading}
              icon={<PendingActionsIcon sx={{ fontSize: "32px" }} />}
              title={objDashboardCounts.data[0].PendingRevenueSubmission.label}
              value={Utils.showNumber(
                objDashboardCounts.data[0].PendingRevenueSubmission.value
              )}
            />
          </a>
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <a href={"/tax-filings"} target="_blank" rel="noreferrer">
            <DashboardCard
              isLoading={objDashboardCounts.isLoading}
              icon={<TelegramIcon sx={{ fontSize: "32px" }} />}
              title={objDashboardCounts.data[0].TotalTaxFilled.label}
              value={Utils.showNumber(
                objDashboardCounts.data[0].TotalTaxFilled.value
              )}
            />
          </a>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <a href={"/tax-filings"} target="_blank" rel="noreferrer">
            <DashboardCard
              isLoading={objDashboardCounts.isLoading}
              icon={<RuleIcon sx={{ fontSize: "32px" }} />}
              title={objDashboardCounts.data[0].FailedFilings.label}
              value={Utils.showNumber(
                objDashboardCounts.data[0].FailedFilings.value
              )}
            />
          </a>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <a href={"/tax-filings"} target="_blank" rel="noreferrer">
            <DashboardCard
              isLoading={objDashboardCounts.isLoading}
              icon={<FileDownloadDoneIcon sx={{ fontSize: "32px" }} />}
              title={objDashboardCounts.data[0].submittedRevenue.label}
              value={Utils.showNumber(
                objDashboardCounts.data[0].submittedRevenue.value
              )}
            />
          </a>
        </Grid>
      </Grid>
    </>
  );
}
