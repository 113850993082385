// import useApiCall from "hooks/useApiCall";
// import { useEffect } from "react";
import Loading from "components/assets/Loading";
import ErrorPlaceHolder from "common/ErrorPlaceHolder";

// export function withData<P extends { data?: any }>(
//   Component: React.ElementType<P>,
//   fetchData: () => Promise<any>
// ) {
//   return (props: JSX.IntrinsicElements & P) => {
//     const apiCall = useApiCall<any>(fetchData);
//     useEffect(() => {
//       apiCall.init();
//     }, []);
//     if (apiCall.loading) return <Loading />;
//     if (apiCall.error) return <ErrorPlaceHolder ex={apiCall.error} />;
//     return <Component {...props} data={apiCall.data} />;
//   };
// }

import React from "react";
import { Subtract } from "utility-types";

export interface IWithDataType {
  data?: any;
}

export const withData = <P extends IWithDataType>(
  Component: React.ComponentType<P>,
  fetchData: (props: P) => Promise<any>,
  options?: { cacheKey?: string }
) => {
  return class WithData extends React.Component<
    Subtract<P, IWithDataType> & Partial<IWithDataType>
  > {
    state = {
      isLoading: true,
      error: undefined,
      data: undefined,
    };

    componentDidMount() {
      this.setState({ isLoading: true });
      fetchData(this.props as any)
        .then((data) => {
          this.setState({ data });
        })
        .catch((ex) => this.setState({ error: ex }))
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }

    render() {
      if (this.state.isLoading) return <Loading />;
      if (this.state.error) return <ErrorPlaceHolder ex={this.state.error} />;
      return <Component {...(this.props as P)} data={this.state.data || this.props?.data} />;
    }
  };
};

export default withData;
