import { LoadingButton } from "@mui/lab";
import { Divider, Icon, IconButton, Typography } from "@mui/material";
import RIcon from "common/misc/RIcon";
import { ReactNode } from "react";

/**
 * @category Frontend
 * @subcategory Modal
 * @description
 * - When title is not provided to modal, it will not show title.
 * - This component is used to show title of the modal.
 * - By default it will show back button on the left side.
 * - If you want to show close button on the right side, you can pass `closeButton` as `CROSS`.
 * - It will show save button on the right side by default.
 * - If you want to hide save button, you can pass `noSaveButton` as `true`.
 * You can also pass custom save button to the right side by passing `customSaveButton` as ReactNode.
 *
 * @param props - The props for the ModalTitle component
 * @param props.title - The title of the modal (string or ReactNode)
 * @param props.onBack - Function to call when the back button is clicked
 * @param props.onSave - Function to call when the save button is clicked
 * @param props.loading - Whether the save button should show a loading state
 * @param props.customSaveButton - Custom save button to display instead of the default
 * @param props.closeButton - Type of close button to display ('BACK' or 'CROSS')
 * @param props.noSaveButton - Whether to hide the save button
 * @param props.sticky - Whether the title should stick to the top of the modal
 * @param props.saveButtonProps - Additional props to pass to the save button
 */
export default function ModalTitle(props: {
  title: string | ReactNode;
  onBack?: () => void;
  onSave?: () => void;
  loading?: boolean;
  customSaveButton?: any;
  closeButton?: "BACK" | "CROSS";
  noSaveButton?: boolean;
  sticky?: boolean;
  saveButtonProps?: React.ComponentProps<typeof LoadingButton>;
}) {
  const closeButton = props.closeButton || "BACK";
  return (
    <>
      <div
        className="draggable-handle"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "2px 0px",
          ...(props.sticky
            ? { background: "white", position: "sticky", top: 0, zIndex: 5 }
            : {}),
          // backgroundColor: "white",
        }}
      >
        {closeButton === "BACK" && (
          <IconButton onClick={props.onBack} sx={{ pointerEvents: "auto" }}>
            <Icon>arrow_back</Icon>
          </IconButton>
        )}
        {typeof props.title === "string" ? (
          <Typography>{props.title}</Typography>
        ) : (
          props.title
        )}
        <div style={{ flexGrow: 1 }}></div>
        {props.customSaveButton}

        {!props.noSaveButton && (
          <LoadingButton
            // disabled={objForm ? !objForm?.formState.isDirty: undefined}
            loading={props.loading}
            type="submit"
            onClick={props.onSave}
            {...props.saveButtonProps}
          >
            Save
          </LoadingButton>
        )}

        {closeButton === "CROSS" && (
          <IconButton
            onClick={props.onBack}
            sx={{ pointerEvents: "auto", marginLeft: 1 }}
          >
            <RIcon name="close" color="primary" />
          </IconButton>
        )}
      </div>
      <Divider sx={{ marginBottom: 2 }} />
    </>
  );
}
