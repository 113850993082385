import { Box } from "@mui/material";
import { MASTER_CLIENT_FEATURE } from "commonlib";
import MasterClientGuard from "guards/MasterClientGuard";
import { Case, Switch } from "react-if";
import AuthServices from "services/AuthServices";
import { useMasterClientID } from "stores/authStore";
import ReportAutomationDashboard from "./report-automation/ReportAutomationDashboard";
import TaxFilingAutomationDashboard from "./tax-filing-automation/TaxFilingAutomationDashboard";
import TaxFilingAutomationDashboardForMasterClient from "./tax-filing-automation/TaxFilingAutomationDashboardForMasterClient";
export default function Dashboard() {
  let MasterClientID = useMasterClientID();

  return (
    <Box sx={{ m: 2 }}>
      <MasterClientGuard fallback={null}>
        {AuthServices.isFeatureEnabled(
          MASTER_CLIENT_FEATURE.REPORT_AUTOMATION
        ) && <ReportAutomationDashboard />}

        {AuthServices.isFeatureEnabled(
          MASTER_CLIENT_FEATURE.TAX_FILING_AUTOMATION
        ) && (
          <Switch>
            <Case condition={AuthServices.is("ADMIN")}>
              <TaxFilingAutomationDashboard />
            </Case>
            <Case condition={!AuthServices.is("ADMIN")}>
              <TaxFilingAutomationDashboardForMasterClient
                MasterClientID={MasterClientID || "NA"}
              />
            </Case>
          </Switch>
        )}
      </MasterClientGuard>
    </Box>
  );
}
