import { Badge, Chip, Grid, useTheme } from "@mui/material";
import ReactQueryLoadingError from "common/custom/ReactQueryLoadingError";
import {
  CommonDateUtils,
  ITaxFillingsSummary,
  SUB_CLIENT_BUSINESS_TYPE,
} from "commonlib";
import { TourismTaxStore } from "components/tourism-tax-filings/TourismTaxStore";
import { useRouter } from "next/router";
import { useQuery } from "react-query";
import { bSdk } from "services/BackendSDKService";
import { useMasterClientID } from "stores/authStore";

interface ITaxFilingAutomationDashboardForMasterClientProps {
  MasterClientID: string;
}
export default function TaxFilingAutomationDashboardForMasterClient(
  props: ITaxFilingAutomationDashboardForMasterClientProps
) {
  let theme = useTheme();
  let router = useRouter();
  let q = useQuery<ITaxFillingsSummary>(
    ["tax-filing-summary-for-landing-page", props.MasterClientID],
    async () => {
      let result = await bSdk.TaxFilings_GetTaxFilingSummary({
        MasterClientID: props.MasterClientID || "",
        Month: CommonDateUtils.getPreviousMonthYYYYMM(),
      });
      return result.data;
    },
    {
      cacheTime: 0,
    }
  );

  let isSalesTaxEnabled = q.data?.SalesTaxSummary.find((item) => !!item.count);
  let isTourismTaxEnabled = q.data?.TourismTaxSummary.find(
    (item) => !!item.count
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <ReactQueryLoadingError q={q} />
      </Grid>
      {isSalesTaxEnabled && (
        <Grid item xs={12}>
          <hr />
          <div className="text-blue text-large">
            State Sales & Use Tax accounts
          </div>
          {q.data?.SalesTaxSummary.map((item) => {
            if (item.count === 0) return null;
            return (
              <Badge key={item.label} badgeContent={item.count} sx={{ m: 1 }}>
                <Chip
                  label={item.label}
                  sx={{
                    backgroundColor: item.color,
                    color: theme.palette.getContrastText(
                      item.color || "#FFFFFF"
                    ),
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    router.push(`/tax-filings?status=${item.key}`);
                  }}
                />
              </Badge>
            );
          })}
        </Grid>
      )}
      {isTourismTaxEnabled && (
        <Grid item xs={12}>
          <hr />
          <div className="text-blue text-large">
            County Tourism Tax accounts
          </div>
          {q.data?.TourismTaxSummary.map((item) => {
            if (item.count === 0) return null;
            return (
              <Badge key={item.label} badgeContent={item.count} sx={{ m: 1 }}>
                <Chip
                  label={item.label}
                  sx={{
                    backgroundColor: item.color,
                    color: theme.palette.getContrastText(
                      item.color || "#FFFFFF"
                    ),
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    TourismTaxStore.update((s) => {
                      s.SelectedTaxFilingStatus = item;
                    });
                    router.push(`/tourism-tax-filings`);
                  }}
                />
              </Badge>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
}
