import config from "config";
import { format } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

export function convertToServerDateISO(date?: any) {
  if (!date) return "";
  return zonedTimeToUtc(date, config.SERVER_TIMEZONE).toISOString();
}

export function convertToServerDate(date?: any) {
  if (!date) return "";
  return zonedTimeToUtc(date, config.SERVER_TIMEZONE);
}

export function parseDateFromYYYYMM(strDate: string) {
  if (typeof strDate !== "string") return strDate as any as Date;
  return new Date(`${strDate.replace(/-/g, "/")}/02`);
}

export function convertDateToYYYYMM(date: Date | string) {
  return format(parseDate(date), "yyyy-MM");
}

export function convertDateToYYYYMMDD(date: Date | string) {
  return format(parseDate(date), "yyyy-MM-dd");
}

export function parseDateWithoutTimeZone(strDate: any) {
  let date = strDate;
  if (typeof strDate === "string") date = strDate.replace("Z", "");
  return new Date(date);
}

export function parseDate(date: any) {
  if (typeof date === "object") return new Date(date);
  if (typeof date === "string") return new Date(date);
  return date;
}
