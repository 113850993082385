import { CardContent, Typography, CardActions, Button, Card, Grid, IconButton } from '@mui/material'
import { Box } from '@mui/system';
import LoadingCircularProgress from 'common/LoadingCircularProgress';
import React from 'react'

interface IDashboardCardProps {
    icon: React.ReactNode | any;
    title: string;
    value: string | number;
    isLoading: boolean;
}
const DashboardCard = (props: IDashboardCardProps) => {
    return (
        <>
            <Card sx={{ minWidth: 75 }}>
                <CardContent>
                    <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid item xs={3} sx={{
                            display: "flex", justifyContent: "center", alignItems: "center"
                        }}>

                            {props.isLoading ? <LoadingCircularProgress /> : props.icon}
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body1" fontSize={"35px"} color="initial">{props.value}</Typography>
                        </Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={9} sx={{ marginTop: "-12px" }}>
                            <Typography variant="caption" fontSize={"18px"} >{props.title}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}

export default DashboardCard
