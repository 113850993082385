import { Alert, Typography } from "@mui/material";
import React from "react";
import { FieldErrorsImpl, useFormContext } from "react-hook-form";

function getFlatErrorList(errors: FieldErrorsImpl<any>) {
  let lstErrors: any[] = [];
  let aryErrors = getErrorMessage(errors);
  if (Array.isArray(aryErrors)) lstErrors = lstErrors.concat(aryErrors);
  if (typeof aryErrors === "string") lstErrors.push(aryErrors);
  return lstErrors;
}

function getErrorMessage(objErr: any): string | any[] {
  if (!objErr) return [];
  let lstMessages = [];
  if (Array.isArray(objErr)) {
    let aryErrorList: any[] = [];
    for (let obj of objErr) {
      let lstError = getErrorMessage(obj);
      if (Array.isArray(lstError)) {
        aryErrorList = aryErrorList.concat(lstError);
      }
    }
    lstMessages = aryErrorList;
  }
  if (typeof objErr === "object" && !objErr?.message) {
    // It's nested error
    let aryErrorList: any[] = [];
    let lstError = Object.values(objErr);
    for (let obj of lstError) {
      aryErrorList = aryErrorList.concat(getErrorMessage(obj));
    }
    lstMessages = aryErrorList;
  }

  if (typeof objErr === "object" && objErr?.message) {
    lstMessages = [objErr?.message];
  }

  if (typeof objErr === "string") {
    lstMessages = [objErr];
  }

  return lstMessages;
}

export default function ValidationErrorList() {
  const objForm = useFormContext<any>();
  if (!objForm?.formState?.errors) return null;
  // let lstErrors = Object.values(objForm.formState.errors);
  const flatErrorList = getFlatErrorList(objForm?.formState?.errors);
  if (flatErrorList.length === 0) return null;
  return (
    <>
      <Alert icon={false} severity="error">
        {flatErrorList.map((item, index) => {
          return (
            <span key={index}>
              {item?.toString()}
              <br />
            </span>
          );
        })}
      </Alert>
    </>
  );
}
