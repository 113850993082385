const intlObj = new Intl.NumberFormat("en-IN", {
  maximumFractionDigits: 2,
  style: "currency",
  currency: "USD",
});

const percentageObj = new Intl.NumberFormat("en-IN", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  style: "percent",
});

export function prettyPercentage(number?: any) {
  let num = parseFloat(number);
  if (Number.isNaN(num)) return "";
  return percentageObj.format(num / 100);
}
